import { Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';
import { computedFrom } from 'aurelia-binding';
import { Logger } from "aurelia-logging";
import { LogManager, autoinject, Container } from 'aurelia-framework';
import { User, Winery } from './user';
import { LoginService } from 'login/services/login-service';
import { Router } from 'aurelia-router';

@autoinject
export class UserSession{
    static readonly ADDITIONAL_ELAPSED_SECONDS = 3000;
    protected router: Router;
    private logger: Logger;
    user: User;
    currentWinery: Winery;

    constructor(private loginService: LoginService){
        this.router = Container.instance.get(Router);
        this.logger = LogManager.getLogger(UserSession.name);
    }

    @computedFrom('')
    get currentTimeInSeconds(): number{
        return ((new Date()).getTime()) / 1000;
    }    
    
    async logout() {
        this.logger.info("Logging Out");
        await this.loginService.logout();
        this.router.navigateToRoute('dashboard', {}, { trigger: true, replace: true });
        location.reload();
    }

    async getTimeLeftInSeconds(): Promise<number>{        
        let cognitoSession = await this.getCognitoSession();
        let expiration = cognitoSession.getAccessToken().getExpiration();
        let currentTime = Math.max(Date.now() / 1000);
        return Math.ceil(Math.max(expiration - currentTime,0));
    }

    async getTimeLeftInMinutes(): Promise<number>{        
        let timeLeftSeconds = await this.getTimeLeftInSeconds();
        return timeLeftSeconds === 0 ? 0 : Math.ceil(timeLeftSeconds/60);
    }

    async getCognitoSession(): Promise<CognitoUserSession>{
        return await Auth.currentSession();
    }

    async getCognitoUser(): Promise<CognitoUser>{
        return await Auth.currentAuthenticatedUser({bypassCache: true});
    }

    async isValidSession(): Promise<boolean>{
        let cognitoSession = await this.getCognitoSession();
        return cognitoSession.isValid();
    }     

    async refreshToken(){
        try{
            let cognitoSession = await this.getCognitoSession();
            let user = await this.getCognitoUser();
            user.refreshSession(cognitoSession.getRefreshToken(), (err, session: CognitoUserSession) => {
                if(err){
                    this.logger.error(`Could not refresh token ${err}`);
                    throw err;
                }
                this.logger.debug(`Refreshed token`);  
            });
        } catch(err){
            await this.loginService.logout();
            location.reload();
        }
        
    }
}